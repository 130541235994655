export default {
  title: {
    id: 'upload_bank_details_info_modal_title',
    defaultMessage: 'Upload my bank details',
  },
  infoBlockTitle: {
    id: 'upload_bank_details_info_modal_info_block_title',
    defaultMessage: 'Name your file before uploading it',
  },
  infoBlockBody: {
    id: 'upload_bank_details_info_modal_info_block_body',
    defaultMessage:
      'To protect your privacy, we will not be able to display the file you uploaded; you can still see your file name on the website though.',
  },
  adviseText: {
    id: 'upload_bank_details_info_modal_body_advise_text',
    defaultMessage:
      'We advise you to name your file properly before uploading it - so that you won’t forget which bank you’ve used (e.g. Back Market - HSBC.jpg)',
  },
  sameBankDetailsText: {
    id: 'upload_bank_details_info_modal_body_same_bank_details',
    defaultMessage:
      'Please make sure that your bank details (IBAN and BIC) are the same as what you registered on My BuyBack sales',
  },
  continueToUpload: {
    id: 'upload_bank_details_info_modal_cta',
    defaultMessage: 'Continue to upload',
  },
}
