import { createHttpEndpoint } from '../../utils'

import {
  type RetrieveClientProfileData,
  type UpdateClientProfileData,
} from './types'

/**
 * Retrieve Client profile
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/profile/operation/retrieveClientProfile}
 */
export const getProfile = createHttpEndpoint<RetrieveClientProfileData>({
  method: 'GET',
  operationId: 'retrieveClientProfile',
  path: '/bm/client/profile',
})

/**
 * Update Client profile
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/profile/operation/updateClientProfile}
 */
export const putProfile = createHttpEndpoint<UpdateClientProfileData>({
  method: 'PUT',
  operationId: 'updateClientProfile',
  path: '/bm/client/profile',
})
