import { createHttpEndpoint } from '../../utils'

import type { UserProfileData } from './types'

/**
 * Retrieve the status of client profile email verification
 */
export const getUserProfileData = createHttpEndpoint<UserProfileData>({
  method: 'GET',
  operationId: 'retrieveClientProfileEmailStatus',
  path: '/auth/public/v1/users',
})

/**
 * Delete (unlink) a social account previously linked to a client profile
 */
export const deleteUserSocialAccountLink = createHttpEndpoint({
  method: 'DELETE',
  operationId: 'deleteUserSocialAccountLink',
  path: '/auth/public/v1/users/social',
})
