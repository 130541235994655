import { createHttpEndpoint } from '../../utils'

import {
  type CreateClientBillingAddressData,
  type RetrieveClientBillingAddressData,
} from './types'

/**
 * Retrieve Client billing address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/billing-address/operation/retrieveClientBillingAddress}
 */
export const getBillingAddress =
  createHttpEndpoint<RetrieveClientBillingAddressData>({
    method: 'GET',
    operationId: 'retrieveClientBillingAddress',
    path: '/bm/client/addresses/billing',
  })

/**
 * Create Client billing address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/billing-address/operation/createClientBillingAddress}
 */
export const postBillingAddress =
  createHttpEndpoint<CreateClientBillingAddressData>({
    method: 'POST',
    operationId: 'createClientBillingAddress',
    path: '/bm/client/addresses/billing',
  })
