export default {
  title: {
    id: 'myprofile_infos_modal_title',
    defaultMessage: 'Personal Information',
  },
  updatePasswordSuccess: {
    id: 'myprofile_notification_success_password',
    defaultMessage: 'Your password has been updated successfully.',
  },
  error: {
    id: 'myprofile_notification_error',
    defaultMessage:
      'Oops! An error occurred and we were unable to fulfill your request. Please try again later.',
  },
  confirmNewPassword: {
    id: 'confirm_new_password',
    defaultMessage: 'Confirm password',
  },
  currentPassword: {
    id: 'old_password',
    defaultMessage: 'Current password',
  },
  newPassword: {
    id: 'new_password',
    defaultMessage: 'New password',
  },
  passwordNewRequired: {
    id: 'input_password_new_required',
    defaultMessage: 'The password field is required',
  },
  passwordCurrentRequired: {
    id: 'input_password_current_required',
    defaultMessage: 'The password field is required',
  },
  passwordMismatch: {
    id: 'myprofile_password_validation_failed',
    defaultMessage: "These passwords don't match",
  },
  passwordRules: {
    id: 'myprofile_infos_modify_password_rules',
    defaultMessage:
      'At least 8 characters, including 1 uppercase letter, 1 lowercase letter and 1 digit. Because you never know.',
  },
  passwordButtonHideAriaLabel: {
    id: 'input_password_current_button_hide_label',
    defaultMessage: 'Hide password',
  },
  passwordButtonShowAriaLabel: {
    id: 'input_password_current_button_show_aria_label',
    defaultMessage: 'Reveal password',
  },
  save: {
    id: 'myprofile_button_save',
    defaultMessage: 'Save',
  },
}
