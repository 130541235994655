<template>
  <RevContainer>
    <main>
      <h1 class="heading-1 mb-4 mt-0 text-center">
        {{ i18n(translations.title) }}
      </h1>

      <p class="body-2 mb-24 mt-0 text-center md:mb-32">
        {{ i18n(translations.description) }}
      </p>

      <div class="grid gap-24 md:grid-cols-2 md:gap-32">
        <UserInfoCard />
        <BillingAddressCard />
        <ShippingAddressCard />
        <NewsletterPreferencesCard />
        <RibCard v-if="showRibCard" />
      </div>
    </main>
  </RevContainer>
</template>

<script setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'

import { useBuybackProfileConfig } from '~/scopes/buyback/config/useBuybackProfileConfig'

import translations from './Profile.translations'
import BillingAddressCard from './components/AddressCards/BillingAddressCard/BillingAddressCard.vue'
import ShippingAddressCard from './components/AddressCards/ShippingAddressCard/ShippingAddressCard.vue'
import NewsletterPreferencesCard from './components/NewsletterPreferencesCard/NewsletterPreferencesCard.vue'
import RibCard from './components/RibCard/RibCard.vue'
import UserInfoCard from './components/UserInfoCard/UserInfoCard.vue'
import { useLogCapturedError } from './composables/useLogCapturedError'

const buyBackConfig = useBuybackProfileConfig()
const showRibCard = Boolean(buyBackConfig?.showRibCard)
const i18n = useI18n()
useLogCapturedError()
</script>
