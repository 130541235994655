import type { ProblemValidation } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import type { Owner } from '@backmarket/nuxt-module-logger/types'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import translations from './useStandardizedApiValidationErrors.translations'

/**
 * Function to get the standardized API validation errors
 * We don't want to show the error linked to a field that is not in the form also sometimes the API can return an error without a target
 * @param values Values of the form
 * @param error ProblemValidation errors from the API
 */
export function useStandardizedApiValidationErrors(owners: Owner[]) {
  const logger = useLogger()
  const i18n = useI18n()
  const { openErrorToast } = useTheToast()

  function getStandardizedApiValidationErrors<
    FormValues extends Record<string, unknown>,
  >(values: FormValues, apiError: ProblemValidation) {
    const notHandledErrors: string[] = []
    const standardizedErrors = (apiError?.errors || []).reduce(
      (standardizedError, error) => {
        const { target } = error
        // Sometimes the target is prefixed with 'body.', and for some others, the target is prefixed with 'user.'
        const standardizedTarget = target
          ?.replace('body.', '')
          .replace('user.', '')
        const isMissingTarget = !standardizedTarget
        const isTargetNotInTheForm = values[standardizedTarget] === undefined

        // if the target is not defined we can't show the error in the form so we show a toast
        if (isMissingTarget) {
          logger.error(
            '[WRONG_ERROR_FORMAT] The API returned an error without a target or with a target that is not in the form',
            { owners },
          )
        } else if (isTargetNotInTheForm) {
          // if the target is not in the form we cant show the error in the form so we show a toast
          logger.error(
            '[WRONG_ERROR_FORMAT] The API returned an error with a target that is not in the form',
            { target: standardizedTarget, owners },
          )
        }
        if (isMissingTarget || isTargetNotInTheForm) {
          notHandledErrors.push(error.detail || error.title)

          return standardizedError
        }

        return {
          ...standardizedError,
          [standardizedTarget]: error.detail || error.title,
        }
      },
      {} as Record<keyof FormValues, string>,
    )
    if (notHandledErrors.length > 0) {
      openErrorToast({
        title: i18n(translations.validationError),
        content: notHandledErrors.join(', '),
      })
    }

    return standardizedErrors
  }

  return {
    getStandardizedApiValidationErrors,
  }
}
