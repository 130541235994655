import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { getAllMarkets } from '@backmarket/nuxt-module-marketplace/getAllMarkets'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

export function useMarketplaceCountryCodes() {
  const currentMarketplace = useMarketplace().market.marketplace
  const availableCountryCodes = Object.keys(getAllMarkets(currentMarketplace))
  const i18n = useI18n()

  return availableCountryCodes
    .map((countryCode) => ({
      label: i18n.country(countryCode),
      value: countryCode,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}
