<template>
  <div>
    <RevDialog
      background-color="low"
      :name="MODAL_NAMES.KYC.UNLINK_SOCIAL_ACCOUNTS_MODAL"
      :title="i18n(translations.title)"
    >
      <template #body>
        <div class="mb-36">{{ i18n(translations.description) }}</div>
        <RevRadio
          v-for="socialProvider in props.socialProviders"
          :id="socialProvider"
          :key="socialProvider"
          v-model="selectedSocialProvider"
          class="my-32"
          :value="socialProvider"
        >
          <template #label>{{ formatSocialProvider(socialProvider) }}</template>
        </RevRadio>
      </template>
      <template #footer>
        <div class="flex justify-center">
          <RevButton
            id="unlink-button"
            :disabled="isLoading"
            full-width="always"
            :loading="isLoading"
            type="submit"
            variant="primary"
            @click="handleSubmit"
          >
            {{ i18n(translations.unlink) }}
          </RevButton>
        </div>
      </template>
    </RevDialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { deleteUserSocialAccountLink } from '@backmarket/http-api/src/api-specs-identity/user/profile'
import { type DeleteUserSocialAccountLinkQueryParams } from '@backmarket/http-api/src/api-specs-identity/user/types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { capitalize } from '@backmarket/utils/string/capitalize'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { closeModal } from '@ds/components/ModalBase'
import { RevRadio } from '@ds/components/Radio'

import { MODAL_NAMES } from '~/scopes/dashboard/constants'

import translations from './UnlinkSocialAccountsModal.translations'

const props = defineProps<{
  socialProviders: string[]
}>()

const logger = useLogger()
const i18n = useI18n()
const { openErrorToast, openSuccessToast } = useTheToast()
const isLoading = ref(false)
const defaultValue = props.socialProviders[0]

const selectedSocialProvider = ref(defaultValue)

const queryParams = computed<DeleteUserSocialAccountLinkQueryParams>(() => ({
  provider: selectedSocialProvider.value,
}))

const emit = defineEmits(['update-profile-info'])

function formatSocialProvider(provider: string): string {
  return provider === 'line' ? 'LINE' : capitalize(provider)
}

function handleError() {
  openErrorToast({
    title: i18n(translations.errorTitle),
    content: i18n(translations.errorContent),
  })
}

const handleSubmit = async () => {
  try {
    isLoading.value = true

    await $httpFetch(deleteUserSocialAccountLink, {
      queryParams: queryParams.value,
    })

    openSuccessToast({
      title: i18n(translations.successTitle),
      content: i18n(translations.successContent),
    })
    closeModal(MODAL_NAMES.KYC.UNLINK_SOCIAL_ACCOUNTS_MODAL)
    emit('update-profile-info')
  } catch (err) {
    const error = err as Error
    logger.error(error.message, {
      error,
      owners: ['bot-squad-sales-customer-care-front'],
    })
    handleError()
  } finally {
    isLoading.value = false
  }
}
</script>
