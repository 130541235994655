export default {
  header: {
    id: 'dashboard_order_billing_address',
    defaultMessage: 'Billing Address',
  },
  modalTitle: {
    id: 'dashboard_order_billing_address',
    defaultMessage: 'Billing Address',
  },
  emptyAddress: {
    id: 'myprofile_addresses_empty_state',
    defaultMessage: 'There is no address registered yet.',
  },
  updateSuccess: {
    defaultMessage: 'Your billing address has been updated successfully.',
    id: 'myprofile_notification_success_addresses_billing',
  },
  validationErrorPhoneNumberMismatch: {
    defaultMessage: 'Phone number does not match with the country dial-in code',
    id: 'myprofile_country_dial_phone_number_mismatch_error',
  },
  validationErrorPostalCodeInvalid: {
    defaultMessage: 'Postal code is invalid',
    id: 'myprofile_postal_code_invalid_error',
  },
}
