<template>
  <ProfileCard
    data-qa="shipping-address-card"
    :title="i18n(translations.header)"
    @edit="openModal(modalName)"
  >
    <AddressComponent
      v-if="hasAddress"
      :address="displayAddress"
      localize-name-order
      with-details
      with-phone
    />
    <p v-else data-test="empty-state">
      {{ i18n(translations.emptyAddress) }}
    </p>
  </ProfileCard>
  <RevDialog
    :name="modalName"
    :title="i18n(translations.header)"
    @close="resetValidationErrors"
  >
    <template #body>
      <AddressForm
        :address="addressData"
        :api-errors
        :country-options
        :is-loading
        show-phone
        @submit="updateAddress"
      />
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { useAsyncData } from '#imports'
import { computed, ref } from 'vue'

import {
  getShippingAddress,
  setShippingAddress,
} from '@backmarket/http-api/src/api-specs-after-sale-experience/client/shipping-address'
import type { RetrieveClientShippingAddressData } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/types'
import AddressComponent from '@backmarket/nuxt-module-address/Address.vue'
import type { Address } from '@backmarket/nuxt-module-address/address'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { RevDialog } from '@ds/components/Dialog'
import { getPhoneNumberInfos } from '@ds/components/InputPhone'
import { closeModal, openModal } from '@ds/components/ModalBase'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

import ProfileCard from '../../ProfileCard/ProfileCard.vue'
import type { FormValues } from '../components/AddressForm/AddressForm.types'
import AddressForm from '../components/AddressForm/AddressForm.vue'
import { useMarketplaceCountryCodes } from '../composables/useMarketplaceCountryCodes'
import { useValidationErrors } from '../composables/useValidationErrors'
import { addressIsEmpty } from '../utils/addressIsEmpty'
import { isValidationError } from '../utils/isValidationError'

import translations from './ShippingAddressCard.translations'

const i18n = useI18n()
const { logHttpOnEvent } = useSalesCustomerCareLogger()
const { openErrorToast, openSuccessToast } = useTheToast()

const modalName = 'PROFILE_SHIPPING_ADDRESS'

const apiErrors = ref<{ [key: string]: string }>({})
const addressData = ref<RetrieveClientShippingAddressData | null>(null)
const isLoading = ref<boolean>(false)

isLoading.value = true
const response = await useAsyncData('shippingAddress', async () => {
  return $httpFetch(getShippingAddress, {
    onEvent: (event, context) =>
      logHttpOnEvent({
        event,
        context,
        errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.myProfile} Error when fetching customer shipping address`,
      }),
  })
})
isLoading.value = false
if (response.data.value || response.error.value?.statusCode === 404) {
  addressData.value = response.data.value || null
} else if (response.error.value instanceof Error) {
  openErrorToast()
}

const hasAddress = computed(() => !addressIsEmpty(addressData.value))
const displayAddress = computed(() => addressData.value as unknown as Address)

type CountryOption = { label: string; value: string }
const countryOptions = computed<CountryOption[]>(() =>
  useMarketplaceCountryCodes(),
)

interface RequestBody extends FormValues {
  countryDialInCode: string
}

function getPhoneProperties(
  formValues: FormValues,
): { countryDialInCode: string; phone: string } | null {
  if (formValues.phone && formValues.country) {
    const { dial, nationalNumber } = getPhoneNumberInfos(
      formValues.phone,
      formValues.country,
    )

    return {
      countryDialInCode: dial,
      phone: nationalNumber,
    }
  }

  return null
}

function transformRequestData(formValues: FormValues): RequestBody {
  return {
    ...(removeEmptyValuesInObject(formValues) as unknown as RequestBody),
    ...(getPhoneProperties(formValues) ?? {}),
  }
}

function resetValidationErrors(): void {
  apiErrors.value = {}
}

// eslint-disable-next-line consistent-return
async function updateAddress(formValues: FormValues) {
  resetValidationErrors()
  isLoading.value = true

  try {
    await $httpFetch(setShippingAddress, {
      body: transformRequestData(formValues) as unknown as Record<
        string,
        unknown
      >,
      onEvent: (event, context) =>
        logHttpOnEvent({
          event,
          context,
          errorDetail: {
            request_body: transformRequestData(formValues),
          },
          errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.myProfile} Error when updating customer shipping address`,
        }),
    })
    openSuccessToast({ title: i18n(translations.updateSuccess) })
    addressData.value = {
      ...addressData.value,
      ...formValues,
      // override the `phone` property to make sure it's displayable
      ...getPhoneProperties(formValues),
    }
    closeModal(modalName)
  } catch (error) {
    if (isValidationError(error)) {
      apiErrors.value = useValidationErrors(error.errors, {
        POSTAL_CODE_INVALID: translations.validationErrorPostalCodeInvalid,
        COUNTRY_DIAL_IN_CODE_PHONE_NUMBER_MISMATCH:
          translations.validationErrorPhoneNumberMismatch,
      })
    } else {
      openErrorToast()
    }
  } finally {
    isLoading.value = false
  }
}
</script>
