import { createHttpEndpoint } from '../../utils'

import { type UpdateClientPasswordData } from './types'

/**
 * Update Client password
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/password/operation/updateClientPassword}
 */
export const updatePassword = createHttpEndpoint<UpdateClientPasswordData>({
  method: 'PUT',
  operationId: 'updateClientPassword',
  path: '/bm/client/update_password',
})
