import {
  type HttpUnknownResponsePayloadRecord,
  createHttpEndpoint,
} from '../../utils'

export type NewsletterPreference = {
  name: string
  optin: boolean
}

export type GetNewsletterPreferencesResponse = Array<NewsletterPreference>

/**
 * Newsletter preferences
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/crm-service-api/definition#tag/Preference/operation/get-customer-newsletter-preferences-v1}
 */
export const getNewsletterPreferences =
  createHttpEndpoint<GetNewsletterPreferencesResponse>({
    method: 'GET',
    operationId: 'getNewsletterPreferences',
    path: '/crm/preferences/v1/newsletters',
  })

/**
 * Update newsletter preference
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/crm-service-api/definition#tag/Preference/operation/update-customer-newsletter-preferences-v1}
 */
export const patchNewsletterPreferences = createHttpEndpoint<
  HttpUnknownResponsePayloadRecord,
  NewsletterPreference
>({
  method: 'PATCH',
  operationId: 'setNewsletterPreferences',
  path: '/crm/preferences/v1/newsletters/optin',
})
