<template>
  <div>
    <RevDialog
      background-color="low"
      :name="MODAL_NAMES.KYC.PROFILE_INFO_EDIT"
      :title="i18n(translations.title)"
    >
      <template #body>
        <RevForm
          class="flex h-full flex-col justify-between"
          :initial-values="profileInfo"
          :name="INFO_EDIT_FORM_NAME"
          :validate
          @submit="handleSubmit"
        >
          <template #default="{ values, errors, hasError }">
            <div class="space-y-16">
              <div class="flex space-x-4">
                <RevInputText
                  id="first-name"
                  v-model="values.firstName"
                  autocomplete="given-name"
                  class="mt-16 w-1/2"
                  :disabled="isLoading"
                  :error="errors.firstName"
                  :has-cleaning-icon="true"
                  :label="i18n(translations.firstName)"
                />

                <RevInputText
                  id="last-name"
                  v-model="values.lastName"
                  autocomplete="family-name"
                  class="mt-16 w-1/2"
                  :disabled="isLoading"
                  :error="errors.lastName"
                  :has-cleaning-icon="true"
                  :label="i18n(translations.lastName)"
                />
              </div>

              <div v-if="displayJapanFields" class="flex space-x-4">
                <RevInputText
                  id="katakana-first-name"
                  v-model="values.katakanaFirstName"
                  class="mt-16 w-1/2"
                  :disabled="isLoading"
                  :error="errors.katakanaFirstName"
                  :has-cleaning-icon="true"
                  :label="i18n(translations.katakanaFirstName)"
                />
                <RevInputText
                  id="katakana-last-name"
                  v-model="values.katakanaLastName"
                  class="mt-16 w-1/2"
                  :disabled="isLoading"
                  :error="errors.katakanaLastName"
                  :has-cleaning-icon="true"
                  :label="i18n(translations.katakanaLastName)"
                />
              </div>

              <RevInputText
                id="email"
                v-model="values.username"
                autocomplete="email"
                :disabled="isLoading"
                :error="errors.username"
                :has-cleaning-icon="true"
                :label="i18n(translations.email)"
              />

              <RevInputPhone
                id="phone-number"
                v-model="values.phone"
                class="isolation-auto"
                :clear-button-aria-label="
                  i18n(translations.phoneNumberInputClear)
                "
                :default-country="market.countryCode"
                description=""
                :error="errors.phone"
                :label="i18n(translations.phoneNumber)"
              />
            </div>

            <div class="mt-7 flex justify-center">
              <RevButton
                id="save-button"
                class="mt-32"
                :disabled="hasError || isLoading"
                full-width="always"
                :loading="isLoading"
                type="submit"
                variant="primary"
              >
                {{ i18n(translations.save) }}
              </RevButton>
            </div>
          </template>
        </RevForm>
      </template>
    </RevDialog>
  </div>
</template>

<script setup lang="ts">
import { useStandardizedApiValidationErrors } from '#imports'
import { computed, ref, watch } from 'vue'

import { HttpApiError, MarketCountryCode } from '@backmarket/http-api'
import * as profileClientApi from '@backmarket/http-api/src/api-specs-after-sale-experience/client/profile'
import type { RetrieveClientProfileData } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import {
  FORM_VALID,
  RevForm,
  type Validator,
  makeValidate,
  matchingRegExp,
  maxLength,
  required,
} from '@ds/components/Form'
import { setFormErrors } from '@ds/components/Form/Form.actions'
import {
  RevInputPhone,
  getPhoneNumberInfos,
  validPhoneNumber,
} from '@ds/components/InputPhone'
import { RevInputText } from '@ds/components/InputText'
import { closeModal } from '@ds/components/ModalBase'

import { REGEXP_VALID_EMAIL } from '~/constants/regex'
import { MODAL_NAMES } from '~/scopes/dashboard/constants'

import {
  INFO_EDIT_FORM_NAME,
  VALIDATION_ERROR,
} from './InfoEditModal.constants'
import translations from './InfoEditModal.translations'

const { market } = useMarketplace()
const i18n = useI18n()
const { openErrorToast, openSuccessToast } = useTheToast()
const { getStandardizedApiValidationErrors } =
  useStandardizedApiValidationErrors(['bot-squad-identity'])
const isLoading = ref(false)

const displayJapanFields = computed(
  () => market.countryCode === MarketCountryCode.JP,
)

const emit = defineEmits(['update-profile-info'])

const props = defineProps<{
  profileData: RetrieveClientProfileData
}>()

type FormValues = {
  firstName: string
  lastName: string
  katakanaFirstName?: string
  katakanaLastName?: string
  username: string
  phone?: string
}

const profileInfo = ref<FormValues>({
  firstName: props.profileData.user.firstName,
  lastName: props.profileData.user.lastName,
  katakanaFirstName: props.profileData.user.katakanaFirstName || '',
  katakanaLastName: props.profileData.user.katakanaLastName || '',
  username: props.profileData.user.username,
  phone: props.profileData.phone,
})

watch(
  () => props.profileData,
  (newProfileData) => {
    profileInfo.value = {
      firstName: newProfileData.user.firstName,
      lastName: newProfileData.user.lastName,
      katakanaFirstName: newProfileData.user.katakanaFirstName || '',
      katakanaLastName: newProfileData.user.katakanaLastName || '',
      username: newProfileData.user.username,
      phone: newProfileData.phone,
    }
  },
  { immediate: true, deep: true },
)

// Same as backend value
const MAX_NAMES_LENGTH = 150

// Only add validation for japanese fields if the country is JP
let japaneseFieldValidation = {}

if (displayJapanFields.value) {
  japaneseFieldValidation = {
    katakanaFirstName: [
      maxLength(MAX_NAMES_LENGTH, i18n(translations.invalidLength)),
    ],
    katakanaLastName: [
      maxLength(MAX_NAMES_LENGTH, i18n(translations.invalidLength)),
    ],
  }
}

const validate = makeValidate<FormValues>({
  firstName: [
    maxLength(MAX_NAMES_LENGTH, i18n(translations.invalidLength)),
    required(i18n(translations.required)),
  ],
  lastName: [
    maxLength(MAX_NAMES_LENGTH, i18n(translations.invalidLength)),
    required(i18n(translations.required)),
  ],
  username: [
    required(i18n(translations.required)),
    matchingRegExp(REGEXP_VALID_EMAIL, i18n(translations.invalidEmail)),
  ],
  phone: [
    ((value, values, ctx) =>
      value
        ? validPhoneNumber(i18n(translations.invalidPhone))(value, values, ctx)
        : FORM_VALID) as Validator<FormValues>,
  ],
  ...japaneseFieldValidation,
})

function handleError(httpError: HttpApiError) {
  const { type } = httpError
  if (type === VALIDATION_ERROR) {
    const formErrors = getStandardizedApiValidationErrors(
      profileInfo.value,
      httpError,
    )
    setFormErrors(INFO_EDIT_FORM_NAME, formErrors)
  } else {
    openErrorToast({ content: i18n(translations.genericError) })
  }
}

const handleSubmit = async (values: FormValues) => {
  try {
    isLoading.value = true

    let contact = {}

    if (values.phone) {
      const { internationalNumber, country } = getPhoneNumberInfos(
        values.phone,
        market.countryCode,
      )

      contact = {
        countryDialInCode: country,
        phone: internationalNumber,
      }
    }

    let japaneseFields = {}

    if (displayJapanFields.value) {
      japaneseFields = {
        katakanaFirstName: values.katakanaFirstName,
        katakanaLastName: values.katakanaLastName,
      }
    }

    await $httpFetch(profileClientApi.putProfile, {
      body: {
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          username: values.username,
          ...japaneseFields,
        },
        ...contact,
      },
    })

    // update values in the component if reused immediately
    profileInfo.value = {
      firstName: values.firstName,
      lastName: values.lastName,
      katakanaFirstName: values.katakanaFirstName,
      katakanaLastName: values.katakanaLastName,
      username: values.username,
      phone: values.phone,
    }

    openSuccessToast({ content: i18n(translations.updateInfoSuccess) })
    closeModal(MODAL_NAMES.KYC.PROFILE_INFO_EDIT)
    emit('update-profile-info')
  } catch (err) {
    const genericError = err as Error
    handleError(genericError as HttpApiError)
  } finally {
    isLoading.value = false
  }
}
</script>
