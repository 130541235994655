<template>
  <RevDialog :name="props.name" :title="i18n(translations.title)">
    <template #body>
      <div class="flex flex-col gap-y-16 space-y-6">
        <RevInfoBlock
          :content="i18n(translations.infoBlockBody)"
          :icon="IconEdit"
          :title="i18n(translations.infoBlockTitle)"
          variant="info"
        />

        <p>
          {{ i18n(translations.adviseText) }}
        </p>

        <RevButton
          full-width="always"
          variant="primary"
          @click="handleContinueUploadButtonClick"
        >
          {{ i18n(translations.continueToUpload) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { closeModal } from '@ds/components/ModalBase'
import { IconEdit } from '@ds/icons/IconEdit'

import { MODAL_NAMES } from '~/scopes/dashboard/constants'

import translations from './RibUploadModal.translations'

const i18n = useI18n()

interface BankDetailsModalProps {
  name: string
}

const props = defineProps<BankDetailsModalProps>()

const emit = defineEmits<{
  (e: 'continue-upload'): void
}>()

function handleContinueUploadButtonClick() {
  emit('continue-upload')
  closeModal(MODAL_NAMES.KYC.RIB_UPLOAD_MODAL)
}
</script>
