export default {
  title: {
    id: 'myprofile_preferences_title_new',
    defaultMessage: 'My Preferences',
  },
  goodDeals: {
    id: 'newsletter_preferences_bon_plan',
    defaultMessage: 'Get the best deals out there.',
  },
  sletter: {
    id: 'newsletter_preferences_sletter',
    defaultMessage: 'Get the Sletter, the newsletter with nothing "new" in it.',
  },
} as const
