<template>
  <ProfileCard
    data-test="myprofile-profile-user-info"
    :title="i18n(translations.title)"
    @edit="openModal(MODAL_NAMES.KYC.PROFILE_INFO_EDIT)"
  >
    <div class="flex items-center">
      <div class="body-1">
        <div class="w-[20ch] truncate lg:w-[40ch]">{{ fullName }}</div>
        <div v-if="userProfileDataRetrieved">
          <div
            v-if="!userProfileData.email_properties.verified"
            class="text-static-danger-hi"
          >
            <p class="flex items-center gap-4">
              <IconWarning size="12" />{{ i18n(translations.emailUnverified) }}
            </p>
          </div>
          <div v-else class="text-static-success-hi">
            <p class="flex items-center gap-4">
              <IconCheckInCircle size="12" />{{
                i18n(translations.emailVerified)
              }}
            </p>
          </div>
        </div>
        <div class="w-[20ch] truncate lg:w-[40ch]">
          {{ profileData.user.username }}
        </div>
        <div>{{ phone }}</div>
        <div class="mt-12 flex flex-col gap-16">
          <RevLink
            v-if="
              !userProfileData.email_properties.verified &&
              userProfileDataRetrieved
            "
            :tracking="{
              zone: 'userinfo_card',
              name: 'email_verification',
            }"
            @click="handleVerifyEmail"
          >
            {{ i18n(translations.verifyEmail) }}
          </RevLink>
          <RevLink
            v-if="userProfileData.social.linked_providers.length"
            @click="openModal(MODAL_NAMES.KYC.UNLINK_SOCIAL_ACCOUNTS_MODAL)"
          >
            {{ i18n(translations.unlinkSocialAccounts) }}
          </RevLink>
          <RevLink
            v-if="profileData.user.usablePassword !== false"
            @click="openModal(MODAL_NAMES.KYC.PROFILE_PASSWORD_EDIT)"
          >
            {{ i18n(translations.updatePassword) }}
          </RevLink>
          <RevLink
            data-qa="profile-user-delete-account-link"
            :to="
              resolveLocalizedRoute({
                name: ROUTE_NAMES.DASHBOARD.KYC.DELETE_ACCOUNT,
              })
            "
          >
            {{ i18n(translations.deleteAccount) }}
          </RevLink>
        </div>
      </div>
    </div>

    <InfoEditModal :profile-data @update-profile-info="loadProfileData" />
    <UnlinkSocialAccountsModal
      v-if="userProfileData.social.linked_providers.length"
      :social-providers="userProfileData.social.linked_providers"
      @update-profile-info="loadProfileData"
    />
    <PasswordEditModal />
  </ProfileCard>
</template>

<script setup lang="ts">
import { navigateTo, useRequestURL, useRoute } from '#imports'
import { onBeforeMount, onMounted, ref } from 'vue'

import { HttpApiError } from '@backmarket/http-api'
import { getProfile } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/profile'
import type { RetrieveClientProfileData } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/types'
import { getUserProfileData } from '@backmarket/http-api/src/api-specs-identity/user/profile'
import type {
  PostEmailVerificationResponse,
  UserProfileData,
} from '@backmarket/http-api/src/api-specs-identity/user/types'
import { postEmailVerificationFlow } from '@backmarket/http-api/src/api-specs-identity/user/verification'
import { useAuthOrigin } from '@backmarket/nuxt-layer-oauth/useAuthOrigin'
import { getFullName } from '@backmarket/nuxt-module-address/utils/name/getFullName'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconWarning } from '@ds/icons/IconWarning'
import parsePhoneNumber from 'libphonenumber-js'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { MODAL_NAMES, ROUTE_NAMES } from '~/scopes/dashboard/constants'

import InfoEditModal from '../InfoEditModal/InfoEditModal.vue'
import PasswordEditModal from '../PasswordEditModal/PasswordEditModal.vue'
import ProfileCard from '../ProfileCard/ProfileCard.vue'
import UnlinkSocialAccountsModal from '../UnlinkSocialAccountsModal/UnlinkSocialAccountsModal.vue'

import translations from './UserInfoCard.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()

const { market } = useMarketplace()
const i18n = useI18n()
const { openSuccessToast, openErrorToast } = useTheToast()

const profileData = ref<RetrieveClientProfileData>({
  user: {
    id: -1,
    firstName: '',
    lastName: '',
    username: '',
    usablePassword: false,
    accountCreationDate: '',
  },
  emarsysID: '',
  encryptedUserId: '',
  countryDialInCode: '',
  phone: '',
})

const userProfileDataRetrieved = ref<boolean>(false)
const userProfileData = ref<UserProfileData>({
  email: '',
  email_properties: {
    verified: false,
  },
  social: {
    linked_providers: [],
  },
})
const fullName = ref('')
const phone = ref('')

const loadProfileData = async () => {
  profileData.value = await $httpFetch(getProfile)
  fullName.value = getFullName(
    market.countryCode,
    profileData.value.user.firstName,
    profileData.value.user.lastName,
  )

  phone.value = [
    profileData.value.countryDialInCode,
    profileData.value.phone,
  ].join(' ')
  const phoneNumber = parsePhoneNumber(phone.value)
  if (phoneNumber) {
    phone.value = phoneNumber.formatInternational()
  }

  userProfileData.value = await $httpFetch(getUserProfileData)
  userProfileDataRetrieved.value = true
}

const handleVerifyEmail = async () => {
  const locale = useI18nLocale()
  const { origin } = useRequestURL()
  try {
    const response = (await $httpFetch(postEmailVerificationFlow, {
      body: {
        redirect_to: `${origin}/${locale}/dashboard/profile?from=successful-email-verification`,
      },
    })) as PostEmailVerificationResponse
    const oauthOrigin = useAuthOrigin()
    await navigateTo(
      {
        path: `${oauthOrigin}/${locale}/verification/email`,
        query: {
          flow: response.flow,
        },
      },
      {
        external: true,
      },
    )
  } catch (err) {
    const { status } = err as HttpApiError
    if (status === 429) {
      // too many attempts
      openErrorToast({
        title: i18n(translations.tooManyEmailVerificationAttemptsErrorTitle),
        content: i18n(
          translations.tooManyEmailVerificationAttemptsErrorContent,
        ),
      })
    } else {
      // unexpected error
      openErrorToast()
    }
  }
}

onBeforeMount(async () => {
  const route = useRoute()
  if (
    route.query.from === 'successful-email-verification' &&
    userProfileData.value.email_properties.verified
  ) {
    openSuccessToast({
      title: i18n(translations.emailVerifiedToastTitle),
      content: i18n(translations.emailVerifiedToastContent),
    })
  }
})

onMounted(async () => {
  try {
    await loadProfileData()
  } catch (err) {
    openErrorToast()
  }
})
</script>
