import type { ProblemValidationErrorMember } from '@backmarket/http-api/src/api-models/ProblemValidationErrorMember'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

export const useValidationErrors = (
  errors: ProblemValidationErrorMember[],
  errorMapping: {
    [key: ProblemValidationErrorMember['title']]: I18nDefinition
  },
): { [formField: string]: string } => {
  const i18n = useI18n()
  const validationErrors = errors.reduce((acc, error) => {
    const formField = error.target.split('.').pop()
    if (!formField) return acc

    const message = errorMapping?.[error.title]
      ? i18n(errorMapping[error.title])
      : error.title

    return {
      ...acc,
      [formField]: message,
    }
  }, {})

  return validationErrors
}
