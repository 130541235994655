export default {
  description: {
    id: 'myprofile_description',
    defaultMessage:
      'Who am I? Where am I? Why am I? Look no further for the answers.',
  },
  title: {
    id: 'dashboard_menu_info',
    defaultMessage: 'My profile',
  },
}
