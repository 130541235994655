export default {
  deliveryPhoneHelp: {
    id: 'delivery_phone_explain',
    defaultMessage:
      'We need it for the delivery, just in case we need to reach you.',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
}
