export default {
  body: {
    defaultMessage:
      "We will no longer be able to pay you if you want to resell a device, that's bad. But you can always send it back to us later!",
    id: 'delete_rib_confirmation_modal_body',
  },
  buttonCancel: {
    defaultMessage: 'Cancel',
    id: 'delete_rib_confirmation_modal_button_cancel',
  },
  title: {
    defaultMessage: 'Your last word?',
    id: 'delete_rib_confirmation_modal_title',
  },
}
