export default {
  bankDetailsFilenameHidden: {
    id: 'myprofile_bank_details_filename_not_display',
    defaultMessage:
      'We don’t display your file in order to protect your privacy.',
  },
  bankDetailsSecurityBodyPart1: {
    id: 'myprofile_bank_details_security_message_body_part_1',
    defaultMessage:
      'Please note that it’s mandatory to upload your bank details if you have sold items via BuyBack totalling more than $1000.',
  },
  bankDetailsSecurityBodyPart2: {
    id: 'myprofile_bank_details_security_message_body_part_2',
    defaultMessage:
      'Make sure your bank details (IBAN and BIC) are the same as those you included on the “My BuyBack sales” page',
  },
  bankDetailsSecurityTitle: {
    id: 'myprofile_bank_details_security_message_title',
    defaultMessage:
      'Forgot what you uploaded or want to update your bank details? Just delete this file and upload a new one.',
  },
  bankDetailsUploadSuccess: {
    id: 'myprofile_bank_details_success_upload',
    defaultMessage: "You've successfully uploaded your bank details.",
  },
  bicInvalid: {
    defaultMessage: 'The BIC/SWIFT provided is not valid.',
    id: 'myprofile_bic_invalid',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'myprofile_rib_delete_button',
  },
  description: {
    defaultMessage:
      'You did not provide an IBAN. We will need one when you want to sell back a device!',
    id: 'myprofile_rib_description',
  },
  ibanInvalid: {
    defaultMessage: 'The IBAN provided is not valid.',
    id: 'myprofile_iban_invalid',
  },
  invalidFormat: {
    defaultMessage:
      'Invalid document format. Please provide in one of the following formats: png, jpeg, jpg, pdf.',
    id: 'myprofile_document_format_invalid',
  },
  invalidSize: {
    defaultMessage: 'Document size is either too small or too big.',
    id: 'myprofile_document_size_invalid',
  },
  missingFile: {
    defaultMessage: 'You need to provide a document',
    id: 'myprofile_document_missing',
  },
  modaleConfirmationButtonDelete: {
    defaultMessage: 'Delete my IBAN',
    id: 'delete_rib_confirmation_modal_button_delete',
  },
  preview: {
    defaultMessage: 'Preview',
    id: 'myprofile_rib_preview_button',
  },
  title: {
    defaultMessage: 'RIB',
    id: 'myprofile_upload_rib_title',
  },
  updateBankDetails: {
    defaultMessage: 'Update',
    id: 'myprofile_rib_fullfiled_button',
  },
  uploadBankDetails: {
    defaultMessage: 'Send my IBAN',
    id: 'myprofile_rib_button',
  },
  uploadSuccess: {
    defaultMessage:
      'Your IBAN has been sent, it will be used when you sell back a device.',
    id: 'myprofile_rib_fullfiled_description',
  },
  deleteIbanSuccess: {
    defaultMessage: 'Your IBAN was successfully deleted!',
    id: 'myprofile_notification_success_bank_delete',
  },
  error: {
    defaultMessage:
      'Oops! An error occurred and we were unable to fulfill your request. Please try again later.',
    id: 'myprofile_notification_error',
  },
  uploadIbanSuccess: {
    defaultMessage: 'Your IBAN was successfully sent!',
    id: 'myprofile_notification_success_bank',
  },
}
