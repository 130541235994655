<template>
  <div>
    <RevDialog
      background-color="low"
      :name="MODAL_NAMES.KYC.PROFILE_PASSWORD_EDIT"
      :title="i18n(translations.title)"
    >
      <template #body>
        <RevForm
          :initial-values
          :name="PASSWORD_EDIT_FORM_NAME"
          :validate
          @submit="handleSubmit"
        >
          <template #default="{ values, errors, hasError }">
            <RevInputPassword
              id="old-password"
              v-model="values.oldPassword"
              autocomplete="current-password"
              class="mt-16"
              :disabled="isLoading"
              :error="errors.oldPassword"
              :hide-aria-label="i18n(translations.passwordButtonHideAriaLabel)"
              :label="i18n(translations.currentPassword)"
              :show-aria-label="i18n(translations.passwordButtonShowAriaLabel)"
            />

            <RevInputPassword
              id="new-password"
              v-model="values.newPassword"
              autocomplete="new-password"
              class="mt-16"
              data-test="new-password-input"
              :description="i18n(translations.passwordRules)"
              :disabled="isLoading"
              :error="errors.newPassword"
              :hide-aria-label="i18n(translations.passwordButtonHideAriaLabel)"
              :label="i18n(translations.newPassword)"
              :show-aria-label="i18n(translations.passwordButtonShowAriaLabel)"
            />

            <RevInputPassword
              id="new-password-confirmation"
              v-model="values.newPasswordValidation"
              autocomplete="new-password"
              class="mt-16"
              data-test="new-password-confirmation-input"
              :disabled="isLoading"
              :error="errors.newPasswordValidation"
              :hide-aria-label="i18n(translations.passwordButtonHideAriaLabel)"
              :label="i18n(translations.confirmNewPassword)"
              :show-aria-label="i18n(translations.passwordButtonShowAriaLabel)"
            />

            <div class="mt-7 flex justify-center">
              <RevButton
                id="save-button"
                class="mt-32"
                :disabled="hasError || isLoading"
                full-width="always"
                :loading="isLoading"
                type="submit"
                variant="primary"
              >
                {{ i18n(translations.save) }}
              </RevButton>
            </div>
          </template>
        </RevForm>
      </template>
    </RevDialog>
  </div>
</template>

<script setup lang="ts">
import { useStandardizedApiValidationErrors } from '#imports'
import { ref } from 'vue'

import { HttpApiError } from '@backmarket/http-api'
import * as passwordClientAPI from '@backmarket/http-api/src/api-specs-after-sale-experience/client/password'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { isEqual } from '@backmarket/utils/object/isEqual'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevForm, makeValidate, required } from '@ds/components/Form'
import { setFormErrors } from '@ds/components/Form/Form.actions'
import { RevInputPassword } from '@ds/components/InputPassword'
import { closeModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/dashboard/constants'

import {
  PASSWORD_EDIT_FORM_NAME,
  VALIDATION_ERROR,
} from './PasswordEditModal.constants'
import translations from './PasswordEditModal.translations'

const logger = useLogger()
const i18n = useI18n()
const { openErrorToast, openSuccessToast } = useTheToast()
const { getStandardizedApiValidationErrors } =
  useStandardizedApiValidationErrors(['bot-squad-identity'])
const isLoading = ref(false)

type FormValues = {
  oldPassword: string
  newPassword: string
  newPasswordValidation: string
}

const initialValues: FormValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordValidation: '',
}

const validate = makeValidate<typeof initialValues>({
  oldPassword: [required(i18n(translations.passwordCurrentRequired))],
  newPassword: [required(i18n(translations.passwordNewRequired))],
  newPasswordValidation: [required(i18n(translations.passwordNewRequired))],
})

function handleError(error: HttpApiError) {
  const { type } = error
  if (type === VALIDATION_ERROR) {
    const formErrors = getStandardizedApiValidationErrors(initialValues, error)
    setFormErrors(PASSWORD_EDIT_FORM_NAME, formErrors)
  } else {
    openErrorToast({ content: i18n(translations.error) })
  }
}

const handleSubmit = async (values: FormValues) => {
  try {
    isLoading.value = true

    if (!isEqual(values.newPasswordValidation, values.newPassword)) {
      setFormErrors(PASSWORD_EDIT_FORM_NAME, {
        newPasswordValidation: i18n(translations.passwordMismatch),
      })

      return
    }

    await $httpFetch(passwordClientAPI.updatePassword, {
      body: values,
    })

    openSuccessToast({ content: i18n(translations.updatePasswordSuccess) })
    closeModal(MODAL_NAMES.KYC.PROFILE_PASSWORD_EDIT)
  } catch (err) {
    const error = err as Error
    logger.error(error.message, {
      error,
      owners: ['bot-squad-sales-customer-care-front'],
    })
    handleError(error as HttpApiError)
  } finally {
    isLoading.value = false
  }
}
</script>
