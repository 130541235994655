export default {
  title: {
    id: 'myprofile_infos_unlink_social_accounts_modal_title',
    defaultMessage: 'Choose account(s) to remove',
  },
  description: {
    id: 'myprofile_infos_unlink_social_accounts_modal_description',
    defaultMessage:
      "Once it's removed it can't be used to sign into your Back Market profile unless you add it again.",
  },
  errorTitle: {
    id: 'myprofile_infos_unlink_social_accounts_modal_unlink_toast_error_title',
    defaultMessage: "Change wasn't saved",
  },
  errorContent: {
    id: 'myprofile_infos_unlink_social_accounts_modal_unlink_toast_error_content',
    defaultMessage: 'Something went wrong, try again.',
  },
  successTitle: {
    id: 'myprofile_infos_unlink_social_accounts_modal_unlink_toast_success_title',
    defaultMessage: 'And boom',
  },
  successContent: {
    id: 'myprofile_infos_unlink_social_accounts_modal_unlink_toast_success_content',
    defaultMessage: 'The account has been removed.',
  },
  unlink: {
    id: 'myprofile_infos_unlink_social_accounts_modal_button_unlink',
    defaultMessage: 'Remove',
  },
}
